import en_us from './en'
import fa_ir from './fa'

const lang = "fa"
export { lang }
const direction = {
    fa: "rtl",
    en: 'ltr'
}
function getDircetion() {
    return direction[lang]
}
export { getDircetion }

const fonts = {
    fa: "IRANSans",
    en: "Muli"
}
function getFont() {
    return fonts[lang]
}
export { getFont }

const translates = {
    en: en_us,
    fa: fa_ir
}
function getTranslate() {
    return translates[lang]
}
export { getTranslate }

function changeLanguage(newLang) {
    if (newLang === lang) {
        return
    }
    localStorage.setItem('lang', newLang)
    window.location.reload()
}
export { changeLanguage }