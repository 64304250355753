export default {
    name: "فرشید کرمی",
    home: "خانه",
    about: "درباره من",
    resume: "رزومه",
    portfolios: "نمونه کارها",
    contact: "تماس با ما",
    hi: "سلام من ",
    hi2: " هستم",
    desc: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.",
    aboutme: "معرفی",
    services: "سرویس ها",
    fullname: "نام کامل",
    age: "سن",
    nationality: "ملیت",
    languages: "زبان ها",
    phone: "تلفن تماس",
    years: "سال",
    iranian: "ایران",
    persian: "فارسی",
    english: "انگلیسی",
    downloadcv: "دانلود رزومه",
    webdeisgn: "طراحی وب",
    webdevelopement: "کد نویسی وب",
    mobileapplication: "طراحی اپلیکیشن موبایل",
    servicedesc: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.",
    myskills: "مهارت های من",
    html: "اچ تی ام ال 5",
    javascript: "جاوا اسکریپت",
    react: "ری اکت جی اس",
    php: "پی اچ پی",
    reactnative: "ری اکت نیتیو",
    node: "نود جی اس",
    workingSteps: [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 0, date: "1397-1398", title: "طراح فرانت اند", subtitle: "شرکت بامبو", content: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است." },
        { id: 1, date: "1397-1398", title: "کد نویس بک اند", subtitle: "آسان یادبگیر", content: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است." },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ],
    educationalSteps: [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 0, date: "1397-1398", title: "کارشناسی ارشد", subtitle: "دانشگاه تهران", content: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است." },
        { id: 1, date: "1397-1398", title: "کارشناسی", subtitle: "دانشگاه شهید بهشتی", content: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است." },
        { id: 3, date: "1397-1398", title: "دبیرستان", subtitle: "امام باقر", content: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است." },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ],
    workingexperience: "تجربه های کاری",
    educationalqualifications: "مدارک تحصیلی",
    contactme: "تماس با من",
    getintouch: "ارسال پیام به من",
    enteryourname: "نام  را وارد کنید",
    enteryouremail: "ایمیل  را وارد کنید",
    enteryoursubject: "موضوع را وارد کنید",
    enteryourmessage: "پیغام خود را اینجا بنویسید",
    sendmessage: "ارسال پیام",
    email: "ایمیل",
    address: "آدرس",
    addressdetails: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.",
    portfoliosList: [
        { id: 1, title: "مدرس ری اکت", desc: "مدرس ری اکت در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 2, title: "مدرس ری اکت نیتیو", desc: "مدرس ری اکت نیتیو در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/faradarsreactnative.jpg" },
        { id: 3, title: "مدرس ری اکت", desc: "مدرس ری اکت در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 4, title: "مدرس ری اکت نیتیو", desc: "مدرس ری اکت نیتیو در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/faradarsreactnative.jpg" },
        { id: 5, title: "مدرس ری اکت", desc: "مدرس ری اکت در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 6, title: "مدرس ری اکت نیتیو", desc: "مدرس ری اکت نیتیو در آسان یادبگیر", image: "https://farshidkarami.ir/assets/image/myworks/courses/faradarsreactnative.jpg" },
    ]




}