import React from 'react'
import theme from './theme/theme'
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import IndexSreeen from './screens/index'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { BrowserRouter as Router, } from 'react-router-dom'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function App() {
  return (
    <ThemeProvider theme={theme} >
      <StylesProvider jss={jss} >
        <CssBaseline />
        <Router>
          <IndexSreeen />
        </Router>
      </StylesProvider>
    </ThemeProvider>

  )
}

export default App
