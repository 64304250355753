/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Lesson from '../../components/Lesson';
import LessonVideo from '../../components/LessonVideo';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../config/Apis';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    paddingTop: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  titr: {
    color: theme.palette.primary.main,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
  },
  preDiv: {
    padding: 10,
    backgroundColor: '#193d4a',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hlDiv: {
    padding: 10,
    backgroundColor: '#2f3243',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #2e344e',
  },
}));
export default function LessonDetailsScreen() {
  const classes = useStyles();
  const savedLesson = sessionStorage.getItem('Lesson');
  let lessonDetails = savedLesson ? JSON.parse(savedLesson) : {};
  const { lesson_id } = useParams();
  const [lesson, setLesson] = useState(lessonDetails);
  const [download, setDownload] = useState(lesson.download);
  useEffect(() => {
    let status;
    fetch(`${BASE_URL}lesson/download/${lesson_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.token_type + ' ' + localStorage.token,
      },
    })
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((responseJson) => {
        if (status === 200) {
          setLesson(responseJson.data);
          setDownload(responseJson.download);
        } else {
          setDownload('');
        }
      });
  }, []);
  return lesson.title ? (
    <div className={classes.root}>
      <Helmet>
        <title>{lesson.title}</title>
        <meta name='description' content={lesson.description} />
      </Helmet>

      <Grid direction='row' container style={{ marginTop: 30 }}>
        <Lesson lesson={lesson} source={download} />
        <LessonVideo source={download} />
      </Grid>
    </div>
  ) : (
    <div></div>
  );
}
