import React from 'react'
import { Route } from 'react-router-dom'
import Courses from '../screens/userpanel/CoursesScreen'
import { useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
export default function UserPanel() {
    const { path } = useRouteMatch()
    return (
        <>
            <Helmet>
                <title>پنل کاربری</title>
                <meta name="description" content="وب سایت ReactNativeDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی طراحی اپلیکیشن های موبایل با فریمورک ReactNative و زبان برنامه نویسی جاوااسکرپیت است. و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>

            <Route path={`${path}/courses`}>
                <Courses />
            </Route>
        </>
    )
}
