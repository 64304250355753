import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Title from '../../components/title'
import { getTranslate } from '../../localization/index'
import Skill from '../../components/skill'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import MyStepper from '../../components/MyStepper'
import Teacher from '../../components/Teacher'
import ProfileImage from '../../assets/images/profile2.jpg'
import { Helmet } from 'react-helmet'
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
  },
  skillGrid: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 5,
      paddingLeft: 5,
    },
  },
  skillSecondTitle: {
    marginLeft: 10,
  },
  titr: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}))

export default function FarshidScreen() {
  const classes = useStyles()
  const translate = getTranslate()
  const educationalSteps = [
    { id: -1, date: '', title: '', subtitle: '', content: '' },
    {
      id: 1,
      date: '1391-1392',
      title: 'کنکور کارشناسی',
      subtitle: 'رشته ریاضی',
      content: 'رتبه ۱۹ کنکور ریاضی سال 92',
    },
    {
      id: 1,
      date: '1392-1396',
      title: 'کارشناسی',
      subtitle: 'دانشگاه صنعتی شریف',
      content: 'تحصیل در دانشکده مهندسی برق دانشگاه صنعتی شریف',
    },
    { id: -2, date: '', title: '', subtitle: '', content: '' },
  ]
  const workingSteps = [
    { id: -1, date: '', title: '', subtitle: '', content: '' },
    {
      id: 1,
      date: '1398 - اکنون',
      title: 'شرکت عدل آفرینان ماندگار',
      subtitle: 'برنامه نویس',
      content:
        'طراحی پنل و وبسایت های رزرو بلیط قابل استفاده در مجموعه هایی همچون مجموعه هتل های پارسیان - آژانس اسنپ تریپ - آژانس پرسپولیس - آژانس علی بابا و ...',
    },
    {
      id: 3,
      date: '1398 - اکنون',
      title: 'پروژه حقوق بین الملل لگامارت',
      subtitle: 'طراحی وب سایت کارهای حقوقی بین المللی Legamart',
      content: 'فرانت اند  react js - بک اند Laravel',
    },
    {
      id: 1,
      date: '1397 - 1398',
      title: 'گروه شرکت های ارتباطی شبکه آفتاب',
      subtitle: 'برنامه نویس',
      content: 'طراح وبسایت و اپلیکیشن برنامه استعدادیابی فوتبال ستاره ساز ( پخش از شبکه ۳ )',
    },
    {
      id: 1,
      date: '1397 - اکنون',
      title: 'شرکت فنی مهندسی بامبو، فاتحان آینده',
      subtitle: 'عضو هئیت مدیره شرکت',
      content: 'انجام پروژه برنامه نویسی، طراحی وب سایت، طراحی اپلیکیشن های موبایل و اینترنت اشیا',
    },
    {
      id: 1,
      date: '1396 - 1397',
      title: 'شرکت حامی اندیشه جوانان',
      subtitle: 'برنامه نویس',
      content: 'طراح وبسایت و اپلیکیشن رزرو بلیط هیپو',
    },
    {
      id: 1,
      date: '1394 - 1395',
      title: 'شرکت Protouch',
      subtitle: 'برنامه نویس',
      content: 'برنامه نویس زبان جاوا در طراحی اپلیکیشن سفارش غذا',
    },
    {
      id: 2,
      date: '1394 - اکنون',
      title: 'موسسه آموزش آنلاین آسان یادبگیر',
      subtitle: 'هم بنیان گذار و مدرس مجموعه',
      content:
        'تولید آموزش های ویدئویی و آنلاین از مباحث روز دنیای برنامه نویسی فرانت اند، بک اند، اپلیکیشن موبایل و بازی سازی',
    },
    {
      id: 5,
      date: '1398 - 1398',
      title: 'شرکت مهندسی ساعی بنیان',
      subtitle: 'طراحی اپلیکیشن خانه هوشمند Remonex',
      content: 'طراحی اپلیکیشن با React Native جهت کنترل وسایل خانه توسط سخت افزار طراحی شده',
    },
    {
      id: 6,
      date: '1397 - اکنون',
      title: 'www.ReactNativeDeveloper.ir',
      subtitle: 'طراح، مدیر و مدرس وب سایت',
      content:
        'فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی طراحی اپلیکیشن موبایل اندروید و آی او اس با React Native',
    },
    {
      id: 7,
      date: '1397 - اکنون',
      title: 'www.ReactDeveloper.ir',
      subtitle: 'طراح، مدیر و مدرس وب سایت',
      content: 'فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی فرانت اند با React js',
    },
    {
      id: 8,
      date: '1398 - اکنون',
      title: 'www.flutterlearning.ir',
      subtitle: 'طراح، مدیر و مدرس وب سایت',
      content: 'فرانت اند React js - بک اند با لاراول - تولید دوره های آموزش بک اند نود جی اس و لاراول',
    },
    {
      id: 9,
      date: '1398 - اکنون',
      title: 'www.FlutterLearning.ir',
      subtitle: 'طراح، مدیر و مدرس وب سایت',
      content:
        'فرانت اند React js - بک اند با لاراول - تولید دوره های آموزش طراحی اپلیکیشن موبایل اندروید و آی او اس با زبان دارت و فریمورک فلاتر',
    },
    {
      id: 10,
      date: '1398 - اکنون',
      title: 'www.AiLearning.ir',
      subtitle: 'طراح، مدیر و مدرس وب سایت',
      content: 'فرانت اند React js - بک اند پایتون Django - تولید دوره های آموزشی پردازش تصویر و هوش مصنوعی',
    },
    { id: 15, date: '1397 - 1397', title: 'www.asbambo.ir', subtitle: 'وب سایت شرکتی', content: 'فرانت اند React js' },
    { id: -2, date: '', title: '', subtitle: '', content: '' },
  ]

  return (
    <Grid alignItems='flex-start' container className={classes.root}>
      <Helmet>
        <title>مدرس : حمید کرمی سرینی</title>
        <meta
          name='description'
          content=' وب سایت FlutterLearning یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی طراحی اپلیکیشن های موبایل با فریمورک فلاتر و زبان برنامه نویسی دارت است. و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود.'
        />
      </Helmet>

      <Grid item xs={12} md={12} style={{ padding: 15 }}>
        <Teacher
          image={ProfileImage}
          name='مهندس حمید کرمی'
          teacherProps={[
            'فارغ التحصیل مهندسی برق دانشگاه صنعتی شریف',
            'هم بنیان گذار موسسه آموزش آنلاین آسان یادبگیر',
            'عضو هئیت مدیره شرکت مهندسی بامبو، فاتحان آینده',
            'طراح پنل مدیریت رزرو بلیط هتل استفاده شده در علی بابا، اسنپ تریپ و پرسپولیس',
            'سابقه همکاری با چندین شرکت معتبر',
          ]}
          teacherProps2={[
            'مسلط به طراحی فرانت اند وب سایت ها با  React js',
            'مسلط به کد نویسی سمت سرور / بک اند با زبان پایتون و فریمورک Django',
            'مسلط به کد نویسی سمت سرور / بک اند با زبان جاوا اسکریپت / Node.js',
            'مسلط به طراحی اپلیکیشن موبایل Android  و IOS با زبان جاوااسکریپت / React Native',
            'مسلط به طراحی و پیاده سازی پایگاه های داده SQL و محبوب ترین آن ها  MySQL',
            'مسلط به طراحی و پیاده سازی پایگاه های داده NoSql و محبوب ترین آن ها MongoDB',
            'مسلط به زبان برنامه نویسی دارت و فریمورک فلاتر جهت طراحی اپلیکیشن موبایل اندروید و آی او ااس',
            'مسلط به مباحث هوش مصنوعی / پردازش تصویر / شبکه های عصبی / یادگیری عمیق با زبان پایتون',
          ]}
          mobile='09226686036'
          onClick={() => { }}
        />
      </Grid>

      <Grid className={classes.skillGrid} item container xs={12}>
        <Title title={translate.myskills} />
        <Grid container direction='row'>
          <Grid item xs={12} md={6}>
            <Skill value={100} title='React js' />
            <Skill value={90} title='React Native' />
            <Skill value={80} title='Node.js' />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skill value={90} title='Golang' />
            <Skill value={80} title='Python & Django' />
            <Skill value={90} title='MySQL & MongoDB' />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.skillGrid} item container xs={12}>
        <Title title={translate.resume} />
        <Grid container direction='row' justify='flex-start' alignItems='center'>
          <LocalLibraryIcon style={{ fontSize: 34 }} />
          <Typography className={classes.skillSecondTitle} variant='h4'>
            {translate.educationalqualifications}
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 15, marginBottom: 15 }}>
          <MyStepper steps={educationalSteps} />
        </Grid>
        <Grid container direction='row' justify='flex-start' alignItems='center'>
          <BusinessCenterIcon style={{ fontSize: 34 }} />
          <Typography className={classes.skillSecondTitle} variant='h4'>
            {translate.workingexperience}
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 15, marginBottom: 15 }}>
          <MyStepper steps={workingSteps} />
        </Grid>
        <Typography style={{ marginTop: 15 }} variant='subtitle2'>
          <span className={classes.titr}>توجه: </span>
          تنها پروژه ها و نمونه کارهای مرتبط و مربوط به سال های اخیر در رزومه آمده است. و مدرس دارای تجربه های کاری
          بیشتر می باشد.
        </Typography>
      </Grid>
    </Grid>
  )
}
