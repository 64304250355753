import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      ...theme.typography.subtitle2,
    },
    '& h1': {
      ...theme.typography.h1,
    },
    '& h2': {
      ...theme.typography.h2,
    },
    '& h3': {
      ...theme.typography.h3,
    },
    '& h4': {
      ...theme.typography.h4,
    },
    '& h5': {
      ...theme.typography.h5,
    },
    '& h6': {
      ...theme.typography.h6,
    },
    '& h1,h2,h3,h4,h5,h6': {
      color: theme.palette.primary.main,
      width: '100%',
      textAlign: 'left',
      marginBottom: 15,
    },
    '& a': {
      color: theme.palette.secondary.dark,
    },
    '& img': {
      maxWidth: "100%",
    }
  },
}))
export default function CourseDesc({ description }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
}
