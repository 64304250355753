import React from 'react'
import { Typography, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { getTime } from '../config/Utils'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
    titr: {
        textAlign: 'left',
        flex: 1
    },
    hlDiv: {
        padding: 10,
        backgroundColor: "#333648",
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: "1px solid #454c6b",
        width: "100%",
        cursor: 'pointer',
        marginTop: 10,
        textDecoration: 'none'
    },
    preDiv: {
        backgroundColor: "#273439",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    time: { fontSize: 12, color: theme.palette.primary.main }
}))
export default function LessonsCourse({ topics, slug }) {
    const classes = useStyles()
    let history = useHistory()
    const getDetails = (lessons) => {
        let videoCount = 0
        let homeworkCount = 0
        let podcast = 0
        lessons.forEach(lesson => {
            switch (lesson.type) {
                case 0:
                    videoCount += 1
                    break
                case 1:
                    homeworkCount += 1
                    break
                case 2:
                    podcast += 1
                    break
                default:
                    break
            }
        })
        return (
            <>
                <span style={{ color: "#92a1fd" }} >{videoCount} ویدئوی آموزشی</span>
                {" + "}
                <span style={{ color: "#f764ff" }} >{homeworkCount} تمرین</span>
                {" + "}
                <span style={{ color: "#e7ff5c" }} >{podcast} پادکست صوتی</span>
            </>
        )
    }
    return (
        <>
            {topics.map((topic) =>
                <ExpansionPanel key={topic.id} style={{ backgroundColor: "transparent", }} >
                    <ExpansionPanelSummary className={classes.preDiv} >
                        <Typography className={classes.titr} variant="subtitle2">
                            {topic.title}
                        </Typography>
                        <Hidden smDown>
                            <Typography className={classes.time} variant="subtitle2">
                                {getDetails(topic.lessons)}
                                {" -- "}
                                {getTime(topic.time)} ساعت
                        </Typography>
                        </Hidden>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ flexDirection: 'column' }} >
                        {topic.lessons.map((lesson) =>
                            <Link to={`/courses/${slug}/${lesson.id}`} onClick={() => {
                                sessionStorage.setItem('Lesson', JSON.stringify(lesson))
                            }} key={lesson.id} className={classes.hlDiv}
                                style={{ backgroundColor: "#2f334e" }}
                            >
                                <Typography className={classes.titr} variant="subtitle2">
                                    {lesson.title}{lesson.is_free === 0 ? <span style={{ color: "green" }} >(رایگان)</span> : null}
                                </Typography>
                                <Hidden smDown>

                                    {lesson.type === 0 ? (
                                        <span style={{ color: "#92a1fd" }} >ویدئوی آموزشی</span>
                                    ) : lesson.type === 1 ? (
                                        <span style={{ color: "#f764ff" }} >تمرین</span>
                                    ) : (
                                                <span style={{ color: "#e7ff5c" }} >پادکست صوتی</span>
                                            )}
                                </Hidden>
                            </Link>
                        )}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

            )}

        </>
    )
}
