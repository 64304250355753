import React from 'react'
import { Grid } from '@material-ui/core'
import Title from './title'
import Teacher from './Teacher'
import FarshidProfileImage from '../assets/images/profile.jpg'
import HamidProfileImage from '../assets/images/profile2.jpg'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { scrollToTop } from '../config/Utils'

const useStyles = makeStyles(theme => ({
    contactGrid: {
        paddingTop: 30,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }
    },
    contactGrid2: {
        marginTop: 30
    }
}))
export default function Teachers({ contactGrid }) {
    let history = useHistory()
    const classes = useStyles()
    return (
        <Grid className={contactGrid ? classes.contactGrid : classes.contactGrid2} item container xs={12} >
            <Title title="مدرسین آکادمی" />
            <Grid item xs={12} md={12} style={{ padding: 15 }}  >
                <Teacher
                    image={FarshidProfileImage}
                    name="مهندس فرشید کرمی"
                    teacherProps={[
                        "مدیرعامل شرکت مهندسی بامبو، فاتحان آینده",
                        "هم بنیان گذار موسسه آموزش آنلاین آسان یادبگیر",
                        "دانشجوی ارشد مخابرات دانشگاه شهید بهشتی",
                        "مدرس در مجمتمع فنی تهران",
                        "طراح چندین وب سایت و اپلیکیشن معتبر"
                    ]}
                    teacherProps2={[
                        "مسلط به طراحی فرانت اند وب سایت ها با  React js",
                        "مسلط به کد نویسی سمت سرور / بک اند با زبان PHP و فریمورک Laravel",
                        "مسلط به کد نویسی سمت سرور / بک اند با زبان جاوا اسکریپت / Node.js",
                        "مسلط به طراحی اپلیکیشن موبایل Android  و IOS با زبان جاوااسکریپت / React Native",
                        "مسلط به طراحی و پیاده سازی پایگاه های داده SQL و محبوب ترین آن ها  MySQL",
                        "مسلط به طراحی و پیاده سازی پایگاه های داده NoSql و محبوب ترین آن ها MongoDB",
                        "مسلط به زبان برنامه نویسی دارت و فریمورک فلاتر جهت طراحی اپلیکیشن موبایل اندروید و آی او ااس",
                        "مسلط به مباحث هوش مصنوعی / پردازش تصویر / شبکه های عصبی / یادگیری عمیق با زبان پایتون"
                    ]}
                    mobile="09220479451"
                    onClick={() => { scrollToTop(); history.push('/home/teachers/farshidkarami') }}
                />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: 15 }}  >
                <Teacher
                    image={HamidProfileImage}
                    name="مهندس حمید کرمی"
                    teacherProps={[
                        "فارغ التحصیل مهندسی برق دانشگاه صنعتی شریف",
                        "هم بنیان گذار موسسه آموزش آنلاین آسان یادبگیر",
                        "عضو هئیت مدیره شرکت مهندسی بامبو، فاتحان آینده",
                        "طراح پنل مدیریت رزرو بلیط هتل استفاده شده در علی بابا، اسنپ تریپ و پرسپولیس",
                        "سابقه همکاری با چندین شرکت معتبر"
                    ]}
                    teacherProps2={[
                        "مسلط به طراحی فرانت اند وب سایت ها با  React js",
                        "مسلط به کد نویسی سمت سرور / بک اند با زبان پایتون و فریمورک Django",
                        "مسلط به کد نویسی سمت سرور / بک اند با زبان جاوا اسکریپت / Node.js",
                        "مسلط به طراحی اپلیکیشن موبایل Android  و IOS با زبان جاوااسکریپت / React Native",
                        "مسلط به طراحی و پیاده سازی پایگاه های داده SQL و محبوب ترین آن ها  MySQL",
                        "مسلط به طراحی و پیاده سازی پایگاه های داده NoSql و محبوب ترین آن ها MongoDB",
                        "مسلط به زبان برنامه نویسی دارت و فریمورک فلاتر جهت طراحی اپلیکیشن موبایل اندروید و آی او ااس",
                        "مسلط به مباحث هوش مصنوعی / پردازش تصویر / شبکه های عصبی / یادگیری عمیق با زبان پایتون"
                    ]}
                    mobile="09220479451"
                    onClick={() => { scrollToTop(); history.push('/home/teachers/hamidkarami') }}
                />
            </Grid>

        </Grid>

    )
}
