import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { getTime } from '../config/Utils';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 30,
  },
  main: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#2f3243',
    zIndex: 1,
    border: '1px solid #4f5777',
    borderTop: '5px solid #2e344e',
    // padding: theme.spacing(8),
    // alignItems: 'center',
    transition: '.4s',
    '&:hover': {
      borderTopColor: theme.palette.primary.main,
      transition: '.4s',
    },
  },

  title: {
    marginTop: 15,
    position: 'relative',
    paddingBottom: 15,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 'auto',
      bottom: 0,
      height: 2,
      width: 50,
      background: ' #4f5777',
    },
  },
  desc: {
    marginTop: 5,
    textAlign: 'justify',
  },
  topImage: {
    width: '100%',
  },
  tag: {
    backgroundColor: '#10c98d',
    position: 'absolute',
    right: 0,
    padding: 5,
    color: '#fff',
  },
  priceGrid: {
    background: ' #193d4a',
    borderRadius: 10,
  },
  descriptionBox: {
    padding: 10,
    borderBottom: '1px solid #4f5777',
    flex: 1,
    overflowY: 'auto',
  },
}));

export default function Lesson({ lesson, source }) {
  const classes = useStyles();
  let history = useHistory();
  const onClickProp = () => {
    let a = document.createElement('a');
    a.href = source;
    a.download = source;
    a.click();
  };
  return (
    <Grid item lg={4} md={6} xs={12} className={classes.root}>
      <Grid
        className={classes.main}
        container
        direction='column'
        alignItems='flex-start'
      >
        <Grid container style={{ padding: 10 }}>
          <Typography className={classes.title} variant='h5'>
            {lesson && lesson.title ? lesson.title : ''}
          </Typography>
        </Grid>
        <Grid container className={classes.descriptionBox}>
          <Grid item xs={12} container direction='column'>
            <Typography className={classes.desc} variant='subtitle2'>
              {lesson && lesson.description ? lesson.description : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ padding: 10, borderBottom: '1px solid #4f5777' }}
        >
          <Typography className={classes.desc} variant='caption'>
            طول جلسه : {getTime(lesson.time)} دقیقه
          </Typography>
        </Grid>
        <Grid container style={{ padding: 10 }}>
          <Button
            fullWidth
            onClick={
              source === ''
                ? () => {
                    history.goBack();
                  }
                : onClickProp
            }
            color='primary'
            variant='outlined'
          >
            {source === ''
              ? 'برای دانلود باید دوره را خریداری کنید'
              : 'دانلود این قسمت'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
