import React, { useState } from 'react'
import { Snackbar, SnackbarContent, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    message: {
        textAlign: 'justify'
    },
    action: {
        margin: 0,
        width: "100%",
        padding: 0,
        marginTop: 5
    }
}))
export default function TelegramChanall() {
    const [open, setOpen] = useState(true)
    const classes = useStyles()
    const action = (
        <>
            <Button component="a" href="https://t.me/asanyadbegir_ch" fullWidth color="primary"
                variant="outlined" size="small">
                عضویت در کانال
            </Button>
            <Button component="a" href="https://t.me/asanyadbegirProgramming" fullWidth color="secondary"
                variant="outlined" size="small">
                عضویت در گروه
            </Button>
        </>
    )
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            onClose={() => setTimeout(() => { setOpen(false) }, 3000)}
            autoHideDuration={25000}
        >
            <SnackbarContent style={{
                backgroundColor: "#36439a", flexDirection: 'column',
                maxWidth: 400, lineHeight: 2
            }}
                classes={{ message: classes.message, action: classes.action }}
                message="برای عضویت در کانال ( اطلاع رسانی اتوماتیک قرار گیری قسمت های جدید دوره ها در کانال ) و عضویت در گروه ( پاسخگویی به سوالات ) کلیک کنید."
                action={action}
            >

            </SnackbarContent>
        </Snackbar>
    )
}
