export default {
    name: "Farshid Karami",
    home: "HOME",
    about: "ABOUT",
    resume: "RESUME",
    portfolios: "PORTFOLIOS",
    contact: "CONTACT",
    hi: "Hi, I am ",
    hi2: "",
    desc: "I am a frontend web developer. I can provide clean code and pixel perfect design. I also make website more & more interactive with web animations.",
    aboutme: "ABOUT ME",
    services: "SERVICES",
    fullname: "Full Name",
    age: "Age",
    nationality: "Nationality",
    languages: "Languages",
    phone: "Phone",
    years: "Years",
    iranian: "Iranian",
    persian: "Persian",
    english: "English",
    downloadcv: "download cv",
    webdeisgn: "Web Design",
    webdevelopement: "Web Development",
    mobileapplication: "Mobile Application",
    servicedesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    myskills: "MY SKILLS",
    html: "HTML5",
    javascript: "JavaScript",
    react: "React js",
    php: "PHP",
    reactnative: "React Native",
    node: "node.js",
    workingSteps: [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 0, date: "2018-2019", title: "Frontend Web Developer", subtitle: "Abc University", content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro." },
        { id: 1, date: "2018-2019", title: "Backend Web Developer", subtitle: "Abc University", content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro." },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ],
    educationalSteps: [
        { id: -1, date: "", title: "", subtitle: "", content: "" },
        { id: 0, date: "2018-2019", title: "Master of Science", subtitle: "Abc University", content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro." },
        { id: 1, date: "2018-2019", title: "Bachelor of Science", subtitle: "Abc University", content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro." },
        { id: 3, date: "2018-2019", title: "High School Graduation", subtitle: "Abc University", content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro." },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ],
    workingexperience: "Working Experience",
    educationalqualifications: "Educational Qualifications",
    contactme: "CONTACT ME",
    getintouch: "Get In Touch",
    enteryourname: "Enter your Name",
    enteryouremail: "Enter your Email",
    enteryoursubject: "Enter your Subject",
    enteryourmessage: "Enter your Message",
    sendmessage: "SEND MESSAGE",
    email: "Email",
    address: "Address",
    addressdetails: "121 King Street, Melbourne, Victoria 3000, Australia",
    portfoliosList: [
        { id: 1, title: "reactdevveloper", desc: "react js teacher reactdeveloper.ir", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 2, title: "reactnativedevveloper", desc: "reactnative teacher reactnativedeveloper.ir", image: "https://farshidkarami.ir/assets/image/myworks/courses/faradarsreactnative.jpg" },
        { id: 3, title: "reactdevveloper", desc: "react js teacher", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 4, title: "reactdevveloper", desc: "react js teacher", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 5, title: "reactdevveloper", desc: "react js teacher", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },
        { id: 6, title: "reactdevveloper", desc: "react js teacher", image: "https://farshidkarami.ir/assets/image/myworks/courses/reactnativedeveloper.png" },

    ]


}