import React from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
export default function SuccessMessage({ open, setOpen, message }) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={3000}
        >
            <SnackbarContent style={{ backgroundColor: "green", }}
                message={message}  >

            </SnackbarContent>
        </Snackbar>
    )
}
